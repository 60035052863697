export default {
  case: [
    'created_at',
    'updated_at',
    'archived_at',
    'date_of_birth',
    'date_of_death',
    'date_of_marriage',
    'interment_date',
    'date_entered_service',
    'date_discharged_from_service',
    'service_date',
  ],
  service: ['created_at', 'updated_at', 'date'],
  stationsServed: ['departure_date', 'return_date'],
}
