import { css } from 'styled-components'
import { colors as c } from 'utils'

export default ({
  border,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  borderColor,
  borderOpacity,
}) => css`
  border: ${border};
  border-top: ${borderTop};
  border-right: ${borderRight};
  border-bottom: ${borderBottom};
  border-left: ${borderLeft};
  border-color: ${borderColor && c(borderColor, borderOpacity || null)};
`
