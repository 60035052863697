import React from 'react'
import * as S from './ResizeControls.styled'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  selectedItemAtom,
  itemAtomFamily,
  itemSelector,
  openSwapImageMenuAtom,
} from 'atoms'
import { ResizeHandleElement } from 'components/page/Items/ResizeHandle'

export default ({
  x,
  y,
  z,
  width,
  height,
  rotate,
  internalWidth: [internalWidth, setInternalWidth] = [0, () => {}],
  internalHeight: [internalHeight, setInternalHeight] = [0, () => {}],
  type,
  selected,
  setWidth,
  setHeight,
  disabled,
  isEditing,
  children,
}) => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const deleteItem = useResetRecoilState(itemAtomFamily(selectedItem))
  const [openSwapImageMenu, setOpenSwapImageMenu] = useRecoilState(
    openSwapImageMenuAtom,
  )
  const [imageEdits, setImageEdits] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'image.edits' }),
  )

  const handlePlacements = ['se']

  const minConstraints = type === 'line' ? [2, 2] : [20, 20]
  const axis = type === 'line' ? 'x' : 'both'
  const isImage = type === 'image'

  const setImageEditsState = (width, height) => {
    const imageEditsTmp = { ...imageEdits }
    const updatedWidth = {
      width,
      height,
    }
    imageEditsTmp.targetSize = updatedWidth
    setImageEdits(imageEditsTmp)
  }

  const _openSwapImageMenu = () => {
    setOpenSwapImageMenu(!openSwapImageMenu)
  }

  const onResize = (event, { size: { width, height } }) => {
    setInternalWidth(width)
    setInternalHeight(height)
  }

  const onResizeStop = (event, { size: { width, height } }) => {
    setWidth(width)
    setHeight(height)
    setImageEditsState(width, height)
  }

  const lockedAspectRatioItem = [
    'image',
    'watermark',
    'designAsset',
    'qrCode',
  ].includes(type)

  return (
    <S.ResizeControls
      height={internalHeight || 0}
      width={internalWidth || 0}
      rotate={rotate}
      onResize={onResize}
      onResizeStop={onResizeStop}
      minConstraints={minConstraints}
      selected={selected}
      resizeHandles={handlePlacements}
      lockAspectRatio={lockedAspectRatioItem}
      axis={axis}
      disabled={disabled}
      handle={(position, ref) => {
        return (
          <ResizeHandleElement
            innerRef={ref}
            x={x}
            y={y}
            z={z}
            height={height}
            width={width}
            position={position}
            selected={selected}
          />
        )
      }}
    >
      <>
        {!isEditing && (
          <>
            <S.DeleteItem
              z={z}
              selected={selected}
              onClick={() => deleteItem()}
              fill="brand"
              icon="exitModal"
            />
            {isImage && (
              <S.SwapImage
                z={z}
                selected={selected}
                onClick={() => _openSwapImageMenu()}
                fill="none"
                icon="swap"
              />
            )}
          </>
        )}
        {children}
      </>
    </S.ResizeControls>
  )
}
