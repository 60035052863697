import { css } from 'styled-components'

export default ({ position, top, right, bottom, left, zindex }) => css`
  position: ${position};
  top: ${top};
  right: ${right};
  bottom: ${bottom};
  left: ${left};
  z-index: ${zindex};
`
