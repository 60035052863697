import { caseDetailSpan, replaceInitialValues } from 'utils'

export default (editor, funeralHomeDetails, templates, dateFormat) => {
  const funeralHomeOption1 = replaceInitialValues(
    `
    <p>
      <p>${caseDetailSpan(`funeralHomeDetails.name`)}</p>
      <p>
        ${caseDetailSpan(`funeralHomeDetails.city`)}, ${caseDetailSpan(
      `funeralHomeDetails.state`,
    )}
      </p>
    </p>
  `,
    { funeralHomeDetails },
  )

  const funeralHomeOption2 = replaceInitialValues(
    `
    <p>
      <p>${caseDetailSpan(`funeralHomeDetails.name`)}</p>
      <p>
        ${caseDetailSpan(`funeralHomeDetails.address`)}, 
        ${caseDetailSpan(`funeralHomeDetails.city`)}, 
        ${caseDetailSpan(`funeralHomeDetails.state`)} 
        ${caseDetailSpan(`funeralHomeDetails.zip_code`)}
      </p>
    </p>
  `,
    { funeralHomeDetails },
  )

  const funeralHomeOption3 = replaceInitialValues(
    `
    <p>
      <p>${caseDetailSpan(`funeralHomeDetails.name`)}</p>
      <p>
        ${caseDetailSpan(`funeralHomeDetails.address`)}, 
        ${caseDetailSpan(`funeralHomeDetails.city`)}, 
        ${caseDetailSpan(`funeralHomeDetails.state`)} 
        ${caseDetailSpan(`funeralHomeDetails.zip_code`)}
      </p>
      <p>${caseDetailSpan(`funeralHomeDetails.email_address`)}</p>
    </p>
  `,
    { funeralHomeDetails },
  )

  const funeralMenuOption = [
    funeralHomeOption1,
    funeralHomeOption2,
    funeralHomeOption3,
  ]

  funeralMenuOption.forEach((option, index) => {
    templates.push({
      type: 'menuitem',
      text: `Funeral Home option ${index + 1}`,
      onAction: () => editor.execCommand('mceInsertTemplate', false, option),
    })
  })
}
