import styled, { css } from 'styled-components'
import { disabled } from 'styles'
import { Flex } from 'components'

export const TooltipWrapper = styled(Flex)`
  ${disabled}
`

export const Tooltip = styled(Flex)(
  ({ extraHeight }) => css`
    opacity: 0;
    bottom: 70%;
    transition: 0.15s;
    pointer-events: none;

    ${TooltipWrapper}:hover & {
      bottom: ${`${100 + extraHeight}%`};
      opacity: 1;
    }
  `,
)
