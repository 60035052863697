import { css } from 'styled-components'
import { bgBlur, disabled as itemDisabled } from 'styles'
import { colors as c } from 'utils'

export default ({
  opacity,
  radius,
  background,
  bgOpacity,
  clickable,
  clickThrough,
  blur,
  overflow,
  transition,
  disabled,
  shadow,
}) => css`
  opacity: ${opacity};
  border-radius: ${radius};
  background: ${background && c(background, bgOpacity)};
  cursor: ${clickable && `pointer`};
  pointer-events: ${clickThrough && `none`};
  transition: background-color 0.25s;
  overflow: ${overflow};
  transition: ${transition};
  box-shadow: ${shadow};
  ${blur && bgBlur}
  ${disabled && itemDisabled};
`
