export default ({ initialElement, templateElement }) => {
  const currentStyle = extractCurrentStyle(initialElement)
  const existingStyle = initialElement.attr('style')
  const savedStyle = initialElement.attr('data-style')

  const styling = currentStyle || existingStyle || savedStyle
  if (styling) {
    templateElement('div').attr('style', styling)
    templateElement('div').attr('data-style', styling)
  }
}

const extractCurrentStyle = (element) => {
  if (element.is('span[style]')) return element.attr('style')
  const styledSpan = element.find('span[style]').first()
  return styledSpan.length ? styledSpan.attr('style') : null
}
