import React, { useCallback, useEffect, useMemo, useState } from 'react'
// import { useParams } from 'react-router-dom'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { userAtom, funeralHomeAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { axios } from 'api'
import { user as userApi } from 'api/users'
import { getFuneralHome } from 'api/funeralHomes'
import {
  Archived,
  Landing,
  PdfRender,
  AlignmentSheet,
  SignUp,
  BetaWelcome,
  FamilyUpload,
  NewUser,
} from 'components'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import Auth from 'components/auth/Auth'
import { useAdminValidation } from 'hooks/utility/useAdminValidation'

const AuthProvider = ({ children, isAuthenticated }) => {
  const handleError = useErrorHandler()
  const setUser = useSetRecoilState(userAtom)
  const setFuneralHome = useSetRecoilState(funeralHomeAtom)
  const [token, setToken] = useState('')
  const [error, setError] = useState('')
  const [headerSet, setHeaderSet] = useState(isAuthenticated) // Set initial state based on isAuthenticated
  const logout = usePerformLogout()

  const location = useMemo(() => [window.location.pathname], [])

  const { user, getAccessTokenSilently, isLoading } = useAuth0()

  const localStorageOrderIdToken = localStorage.getItem('messengerOrderIdToken')

  // When auth0's user changes, request a new access token
  useEffect(() => {
    if (!isAuthenticated) {
      // When auth0's user changes, request a new access token
      user && getAccessTokenSilently().then(setToken)
    }
  }, [user, getAccessTokenSilently, isAuthenticated, localStorageOrderIdToken])

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      setHeaderSet(true)
    }
  }, [token])

  // When the access token changes, request the user from the API
  useEffect(() => {
    if (!isAuthenticated) {
      ;(async () => {
        if (token) {
          try {
            const { data: loggedInUser } = await userApi(token)
            if (loggedInUser.archived)
              return logout({
                returnTo: `${window.location.origin}/archived`,
                redirect: true,
              })
            const home = await getFuneralHome(token).catch(function (error) {
              if (
                error.response &&
                'Missing funeral home identification.' ===
                  error.response.data.message
              ) {
                logout({
                  returnTo: `${window.location.origin}/new-user`,
                  redirect: true,
                })
              }
            })
            // TODO: uncomment this when we have a backend fix
            // const { data: logos } = await getFuneralHomeLogos()

            if (loggedInUser.id) {
              setUser(loggedInUser)
              // TODO: uncomment this when we have a backend fix
              // home.logo = logos && (logos.small || logos.medium || logos.large)
            } else if (loggedInUser.statusCode === 401) {
              setError('User error')
            } else {
              setError('Display a Toast?')
              logout({ returnTo: window.location.origin, redirect: true })
            }
            home.id && setFuneralHome(home)
          } catch (error) {
            handleError(error?.response?.data?.message || error.message, error)
          }
        }
      })()
    }
    // eslint-disable-next-line
  }, [token, logout])

  return (
    <>
      {location[0].includes('auth') ? (
        <Auth />
      ) : location[0].includes('new-user') ? (
        <NewUser />
      ) : location[0].includes('archived') ? (
        <Archived />
      ) : location[0].includes('pdfProduct') ? (
        <PdfRender />
      ) : location[0].includes('pdfProofProduct') ? (
        <PdfRender proof={true} />
      ) : location[0].includes('pdfAlign') ? (
        <AlignmentSheet />
      ) : location[0].includes('sign-up') ? (
        <SignUp />
      ) : location[0].includes('beta-welcome') ? (
        <BetaWelcome />
      ) : location[0].includes('familyUpload') ? (
        <FamilyUpload />
      ) : (!isAuthenticated && isLoading && !user?.id) ||
        (!headerSet && !isAuthenticated) ? (
        <Landing />
      ) : (
        children
      )}
      {error && <Landing error={error} />}
    </>
  )
}

export default ({ children }) => {
  useAdminValidation()
  const localStorageOrderIdToken = localStorage.getItem('messengerOrderIdToken')

  const isOrderIdTokenSet = !!localStorageOrderIdToken

  return isOrderIdTokenSet ? (
    <AuthProvider isAuthenticated={true}>{children}</AuthProvider>
  ) : (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
    >
      <AuthProvider isAuthenticated={false}>{children}</AuthProvider>
    </Auth0Provider>
  )
}

export const usePerformLogout = () => {
  const { logout, user } = useAuth0()
  const setUser = useSetRecoilState(userAtom)

  return useCallback(
    ({ redirect = true, redirectTo = '/' } = {}) => {
      const localStorageOrderIdToken = localStorage.getItem(
        'messengerOrderIdToken',
      )
      const isAuthenticatedWithLocalStorage = !!localStorageOrderIdToken

      if (isAuthenticatedWithLocalStorage) {
        localStorage.removeItem('messengerOrderIdToken')
        setUser(null)
      }

      if (user) {
        logout({
          returnTo: window.location.origin,
        })
      } else if (redirect) {
        window.location.href = redirectTo
      }
    },
    [logout, setUser, user],
  )
}
