export default [
  {
    name: 'Afterglow',
    file: 'afterglow',
    format: 'otf',
    weights: ['regular'],
  },
  {
    name: 'AG University',
    file: 'ag-university',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Amazone',
    file: 'amazone',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Amsterdam',
    file: 'amsterdam',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Aniyah',
    file: 'aniyah',
    format: 'otf',
    weights: ['regular'],
  },
  {
    name: 'Arial Black',
    file: 'arial-black',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Arima Madurai',
    file: 'arima-madurai',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Arizonia',
    file: 'arizonia',
    format: 'ttc',
    weights: ['regular'],
  },
  {
    name: 'Athelas',
    file: 'athelas',
    format: 'ttc',
    weights: ['regular'],
  },
  {
    name: 'Autography',
    file: 'autography',
    format: 'otf',
    weights: ['regular'],
  },
  {
    name: 'Avant Garde Gothic',
    file: 'avant-garde-gothic',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Avenir',
    file: 'avenir',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Baskerville',
    file: 'baskerville',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Believe It',
    file: 'believe-it',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Belluga',
    file: 'belluga',
    format: 'otf',
    weights: ['regular'],
  },
  {
    name: 'Big Caslon',
    file: 'big-caslon',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Bitter',
    file: 'bitter',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold'],
  },
  {
    name: 'Bodega Script',
    file: 'bodega-script',
    format: 'otf',
    weights: ['regular'],
  },
  {
    name: 'Book Antiqua',
    file: 'book-antiqua',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Bookman Old Style',
    file: 'bookman-old-style',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Boulevard',
    file: 'boulevard',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Brighton Light',
    file: 'brighton-light-plain',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Brush Script',
    file: 'brush-script',
    format: 'ttf',
    weights: ['italic'],
  },
  {
    name: 'Bullpen',
    file: 'bullpen',
    format: 'ttf',
    weights: ['italic'],
  },
  {
    name: 'Carpenter',
    file: 'carpenter',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Caslon',
    file: 'caslon',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Centaur',
    file: 'centaur',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Century Gothic',
    file: 'century-gothic',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Charm',
    file: 'charm',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Cochin',
    file: 'cochin',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Copper Plate',
    file: 'copper-plate',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Cormorant',
    file: 'cormorant',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Cormorant Infant',
    file: 'cormorant-infant',
    format: 'ttf',
    weights: ['regular', 'italic'],
  },
  {
    name: 'Coronet',
    file: 'coronet-cg',
    format: 'otf',
    weights: ['regular'],
  },
  {
    name: 'Dancing Script',
    file: 'dancing-script',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'DIN',
    file: 'din-condensed',
    format: 'ttf',
    weights: ['bold'],
  },
  {
    name: 'Duende',
    file: 'duende',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'English',
    file: 'english-157',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Eyeglass',
    file: 'eyeglass',
    format: 'ttf',
    weights: ['bold'],
  },
  {
    name: 'Felix Titling',
    file: 'felix-titling',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'FG Jayne Print',
    file: 'fg-jayne-print',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Futura',
    file: 'futura',
    format: 'ttf',
    weights: ['bold'],
  },
  {
    name: 'Gaeilge',
    file: 'gaeilge',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Gloucester',
    file: 'gloucester-mt-extra-condensed',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Gotham',
    file: 'gotham',
    format: 'otf',
    weights: ['regular'],
  },
  {
    name: 'Gotham XNarrow Book',
    file: 'gotham-xnarrow-book',
    format: 'otf',
    weights: ['regular'],
  },
  {
    name: 'Goudy Catalog',
    file: 'goudy-catalog',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Goudy Old Style',
    file: 'goudy-old-style',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold'],
  },
  {
    name: 'Helvetica Neue',
    file: 'helvetica-neue',
    format: 'ttc',
    weights: ['regular'],
  },
  {
    name: 'Kalam',
    file: 'kalam',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Lato',
    file: 'lato',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Liberty',
    file: 'liberty',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Lobster Two',
    file: 'lobster-two',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Lora',
    file: 'lora',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Merienda',
    file: 'merienda',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Minion',
    file: 'minion-pro',
    format: 'otf',
    weights: ['regular'],
  },
  {
    name: 'Mistral',
    file: 'mistral',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Monotype Corsiva',
    file: 'monotype-corsiva',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Monterey',
    file: 'monterey',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Murray Hill',
    file: 'murray-hill',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'New Baskerville',
    file: 'new-baskerville',
    format: 'ttf',
    weights: ['bold'],
  },
  {
    name: 'Nimbus',
    file: 'nimbus-san-p-blac-cond',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Nuptial',
    file: 'nuptial',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Oleo Script',
    file: 'oleo-script',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Omega',
    file: 'omega-sans',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Latin Elongated',
    file: 'opti-latin-elongated',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Otamendi',
    file: 'otamendi',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Papyrus',
    file: 'papyrus',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Park Avenue',
    file: 'park-avenue',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Perpetua',
    file: 'perpetua',
    format: 'ttf',
    weights: ['regular', 'bold', 'italic'],
  },
  {
    name: 'Perpetua Titling',
    file: 'perpetua-titling-mt',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Piranesi It',
    file: 'piranesi-it',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Pristina',
    file: 'pristina',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Ribbon',
    file: 'ribbon-131',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Ruthie',
    file: 'ruthie',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Scriptina',
    file: 'scriptina',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'SF Collegiate',
    file: 'sf-collegiate-solid',
    format: 'ttf',
    weights: ['bold'],
  },
  {
    name: 'Shelley Allegro',
    file: 'shelley-allegro',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Shelley Andante',
    file: 'shelley-andante',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Tangerine',
    file: 'tangerine',
    format: 'ttf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Times New Roman',
    file: 'times-new-roman',
    format: 'ttf',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
  {
    name: 'Times',
    file: 'times',
    format: 'ttc',
    weights: ['regular'],
  },
  {
    name: 'Trajan',
    file: 'trajan-pro',
    format: 'otf',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Vivaldi',
    file: 'vivaldi',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Wedding Text',
    file: 'wedding-text',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'White Oleander',
    file: 'white-oleander-slanted-alt-1',
    format: 'ttf',
    weights: ['regular'],
  },
  {
    name: 'Yana',
    file: 'yana',
    format: 'otf',
    weights: ['regular'],
  },
  {
    name: 'Abyssinica SIL',
    file: 'abyssinica-sil',
    format: 'ttf',
    language: 'Amharic - አማርኛ',
    weights: ['regular'],
  },
  {
    name: 'David Libre',
    file: 'david-libre',
    format: 'ttf',
    language: 'Hebrew עברית',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Frank Ruhl Libre',
    file: 'frank-ruhl-libre',
    format: 'ttf',
    language: 'Hebrew - עברית',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Heebo',
    file: 'heebo',
    format: 'ttf',
    language: 'Hebrew - עברית',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Mirza',
    file: 'mirza',
    format: 'ttf',
    language: 'Arabic - عربى',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Noto Sans KR',
    file: 'noto-sans-kr',
    format: 'otf',
    language: 'Korean - 한국어',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Noto Serif SC',
    file: 'noto-serif-sc',
    format: 'otf',
    language: 'Simplified Chinese - 中文',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Noto Serif TC',
    file: 'noto-serif-tc',
    format: 'otf',
    language: 'Traditional Chinese - 中文',
    weights: ['regular', 'bold'],
  },
  {
    name: 'Open Sans',
    file: 'open-sans',
    format: 'ttf',
    language: 'Vietnamese - Tiếng Việt',
    weights: ['regular', 'italic', 'bold', 'bolditalic'],
  },
]
