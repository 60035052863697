import { handleHtmlData } from 'utils/global'

export default ({ editorProps, templateProps }) => {
  const { caseDetails } = editorProps
  const { fieldName } = templateProps

  const templateData = { fieldName }
  const encodedProperties = handleHtmlData.encode(templateData)

  const text = caseDetails[fieldName]

  if (!text || typeof text !== 'string' || text.trim() === '') return ''

  const firstLetter = text.trim().charAt(0)
  const uppercasedFirstLetter = firstLetter.toUpperCase()

  const letterSpan = `<span class="mceNonEditable">${uppercasedFirstLetter}</span>`

  const html = `
    <div data-template="${fieldName}_first_letter" data-properties="${encodedProperties}" class="mceNonEditable">
      ${letterSpan}
    </div>
  `

  return html
}
