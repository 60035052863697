import { css } from 'styled-components'

export default ({
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  full,
}) => css`
  width: ${full ? '100%' : width};
  min-width: ${minWidth};
  max-width: ${maxWidth};
  height: ${height};
  min-height: ${minHeight};
  max-height: ${maxHeight};
`
