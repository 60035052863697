import { Button, Flex, Text } from 'components'
import * as S from './GeneratingModal.styled'

export const GeneratingModal = ({
  setShowGeneratingModal,
  generationState,
  setGenerationState,
  getOrderProducts,
}) => {
  const pdf = generationState.pdf || ''
  const progress = pdf ? 100 : generationState.progress || 0

  const downloadPDF = () => {
    window.open(pdf)
  }

  const close = () => {
    setShowGeneratingModal(false)
    setGenerationState({})
    getOrderProducts()
  }

  return (
    <Flex height="200px" width="600px" column center gap="32px">
      <Text weight="500" size="24px">
        Generating PDF
      </Text>
      <Flex gap="24px">
        <S.Indicator active={progress >= 25} />
        <S.Indicator active={progress >= 50} />
        <S.Indicator active={progress >= 75} />
        <S.Indicator active={progress >= 100} />
      </Flex>
      <Flex gap="12px">
        <Button onClick={close}>{progress >= 100 ? 'Close' : 'Cancel'}</Button>
        {pdf && (
          <Button primary onClick={downloadPDF}>
            Download PDF
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
