import React, { useEffect } from 'react'
import { useApplyTheme } from 'hooks'
import { GlobalStyles } from 'providers'
import { ToastContainer } from 'components'

export default ({ children }) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, [])

  useApplyTheme()

  return (
    <>
      <GlobalStyles />
      <ToastContainer />
      {children}
    </>
  )
}
