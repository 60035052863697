import { formatDate } from 'utils/dates'
import dynamicTextUtilities from './dynamicTextUtilities'
import preserveStyling from 'utils/tinyMCE/updateDynamicText/preserveStyling'
import cheerio from 'cheerio'

export default (dynamicTextProps) => {
  const { type } = dynamicTextProps.elementProps
  if (type === 'span') handleSimpleCaseDetail(dynamicTextProps)
  if (type === 'div') handleCaseDetailBlock(dynamicTextProps)
}

const handleSimpleCaseDetail = (dynamicTextProps) => {
  const { elementProps, editorProps } = dynamicTextProps
  const { element } = elementProps
  const { dateFormat } = editorProps

  const { getCaseDetail, updateDeepestSpanText } =
    dynamicTextUtilities(dynamicTextProps)

  const { value, type } = getCaseDetail(element)

  let updatedValue = value
  if (type === 'empty') updatedValue = '&nbsp;'
  if (type === 'date') updatedValue = formatDate(value, dateFormat)

  updateDeepestSpanText(element, updatedValue)
}

const handleCaseDetailBlock = (dynamicTextProps) => {
  const { elementProps } = dynamicTextProps
  const { element } = elementProps

  const { getCaseDetail } = dynamicTextUtilities(dynamicTextProps)
  const { path, value } = getCaseDetail(element)

  const divTemplate = `<div class="mceNonEditable" data-case-detail="${path}">${value}</div>`
  const templateElement = cheerio.load(divTemplate)

  preserveStyling({
    initialElement: element,
    templateElement: templateElement,
  })

  element.replaceWith(templateElement.html())
}
