import { css } from 'styled-components'
import { windowsScrollbar, reactDayPicker, tinyMCEStyles } from 'styles'

export default css`
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    font-size: 14px;

    margin: 0;
    overflow-x: hidden;
    width: 100vw;
    min-height: 100vh;
    background: ${({ theme }) => theme.white};
    ${() => windowsScrollbar}
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button,
  input,
  textarea,
  select {
    font-family: 'Inter', sans-serif;
    outline: none;
    border: none;
    transition: 0.25s;
    font-size: 14px;
  }

  /* #bugmuncher_button {
    opacity: 0;
    pointer-events: none;
  } */

  ${() => reactDayPicker}
  ${() => tinyMCEStyles}
`
