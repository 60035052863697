import { handleHtmlData } from 'utils/global'

export default ({ editorProps, templateProps }) => {
  const { caseDetails } = editorProps
  const { fieldName } = templateProps

  const templateData = { fieldName }
  const encodedProperties = handleHtmlData.encode(templateData)

  const date = caseDetails[fieldName]

  if (!date) return ''

  const formattedDate = new Date(date)
  const fullYear = formattedDate.getFullYear()
  const yearSpan = `<span class="mceNonEditable">${fullYear}</span>`

  const html = `
    <div data-template="${fieldName}_year" data-properties="${encodedProperties}" class="mceNonEditable">
      ${yearSpan}
    </div>
  `

  return html
}
