export default (edits, width, height) => {
  const strength = edits?.featherStrength || 6
  const circle = edits?.circle
  const borderRadius = edits?.borderRadius || 0
  const padding = strength * 2.5
  const shapeOffset = `calc(100%25 - ${padding * 2}px)`

  const halfWidth = width / 2
  const halfHeight = height / 2

  // <rect x="${padding}px" y="${padding}px" width="${shapeOffset}" height="${shapeOffset}" rx="${borderRadius}" fill="black"/>
  const rectangle = {
    element: `rect`,
    padding: `x='${padding}px' y='${padding}px'`,
    dimensions: `width='${shapeOffset}' height='${shapeOffset}' rx='${borderRadius}px'`,
  }

  // <ellipse rx="${halfWidth - padding}" ry="${halfHeight - padding}" cx="${halfWidth}" cy="${halfHeight}" fill="black"/>
  const ellipse = {
    element: `ellipse`,
    padding: `rx='${halfWidth - padding}' ry='${halfHeight - padding}'`,
    dimensions: `cx='${halfWidth}' cy='${halfHeight}'`,
  }

  const determineShape = ({ element, padding, dimensions }) =>
    `%3C${element} ${padding} ${dimensions} fill='black'/%3E`

  // data:image/svg+xml,
  const imageType = `data:image/svg+xml,`
  // <svg xmlns='http://www.w3.org/2000/svg' style="filter: blur(${strength}px);" width="${width}px" height="${height}px" fill="none" preserveAspectRatio="none">
  const openingSVG = `%3Csvg xmlns='http://www.w3.org/2000/svg' style='filter: blur(${strength}px);' width='${width}px' height='${height}px' fill='none' preserveAspectRatio='none'%3E`
  // rectangle or ellipse shape as specified above
  const shape = `${determineShape(circle ? ellipse : rectangle)}`
  // </svg>
  const closingSVG = `%3C/svg%3E`

  return `${imageType}${openingSVG}${shape}${closingSVG}`
}
