import {
  differenceInCalendarYears,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  subYears,
  subMonths,
} from 'date-fns'
import { isDate, isoStringToDate } from 'utils'

export default (_case) => {
  let dateOfBirth = _case?.date_of_birth
  let dateOfDeath = _case?.date_of_death

  if (dateOfBirth && dateOfDeath) {
    // convert to date types if they are not already
    if (!isDate(dateOfBirth) || !isDate(dateOfDeath)) {
      dateOfBirth = isoStringToDate(dateOfBirth)
      dateOfDeath = isoStringToDate(dateOfDeath)
    }

    // determines the difference in years
    const age_years = differenceInCalendarYears(dateOfDeath, dateOfBirth)
    dateOfDeath = subYears(dateOfDeath, age_years)

    // determines the difference in months
    const age_months = differenceInCalendarMonths(dateOfDeath, dateOfBirth)
    dateOfDeath = subMonths(dateOfDeath, age_months)

    // determines the difference in days
    const age_days = differenceInCalendarDays(dateOfDeath, dateOfBirth)

    // adds the calculations to the case
    _case.age = `${Math.abs(age_years)}`
    _case.age_years = `${Math.abs(age_years)}`
    _case.age_months = `${Math.abs(age_months)}`
    _case.age_days = `${Math.abs(age_days)}`
  }
}
