import React from 'react'
import { Text } from 'components'
import * as S from './Tooltip.styled'

export const Tooltip = ({ text, children, extraHeight, ...props }) => {
  return (
    <S.TooltipWrapper center column position="relative" {...props}>
      <S.Tooltip
        padding="6px"
        margin="0 0 8px 0"
        radius="2px"
        background="gray1"
        position="absolute"
        extraHeight={extraHeight || 0}
      >
        <Text color="white" size="12px" weight="500" whiteSpace="nowrap">
          {text}
        </Text>
      </S.Tooltip>
      {children}
    </S.TooltipWrapper>
  )
}
