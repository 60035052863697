import { isoStringToDate, convertObjectOfDates } from 'utils'
import { caseDates as dates } from 'mocks'

export default (stations) => {
  if (stations?.length > 0) {
    return stations.map((station) => {
      const parsedStation = JSON.parse(station)
      convertObjectOfDates(dates.stationsServed, parsedStation, isoStringToDate)
      return parsedStation
    })
  } else {
    return []
  }
}
