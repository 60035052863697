import React from 'react'
import * as S from './Divider.styled'

export default ({ whiteBorder, left, padding, ...props }) => (
  <S.Divider
    position="absolute"
    left={`${whiteBorder ? `${left - whiteBorder}` : `${left + padding}`}px`}
    height="100%"
    width={whiteBorder ? '1px' : '0px'}
    background={whiteBorder ? 'white' : undefined}
    top={!whiteBorder ? `0px` : undefined}
    borderLeft={!whiteBorder ? '1px dashed red' : undefined}
    whiteBorder={whiteBorder}
    {...props}
  />
)
