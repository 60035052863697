import { css } from 'styled-components'

export const sizes = {
  phonePlus: 440,
  tablet: 768,
  tabletPlus: 960,
  laptop: 1024,
  laptopPlus: 1200,
  desktop: 1230,
  desktopPlus: 1380,
  monitor: 1500,
}

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media ${label === 'laptop' && 'screen and '} (max-width: ${sizes[
        label
      ]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
