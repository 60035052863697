import React from 'react'
import * as S from './SVG.styled'

export default ({ src, fill, hoverfill, opacity, clickable, ...props }) => {
  return (
    <S.SVG
      src={src}
      fill={fill}
      hoverfill={hoverfill}
      opacity={opacity}
      clickable={clickable ? 1 : 0}
      {...props}
    />
  )
}
