export default {
  table: [
    'created_at',
    'date_of_death',
    'sortable_date_of_death',
    'sortable_created_at',
  ],
  service: ['date'],
  sortableServiceDate: ['date'],
}
