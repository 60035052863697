import styled, { css } from 'styled-components'
import InlineSVG from 'react-inlinesvg'
import { colors as c } from 'utils'
import { spacing, decorations, positioning } from 'styles'

export const SVG = styled(InlineSVG)(
  ({ fill, hoverfill, opacity, ...props }) => css`
    & path {
      fill: ${c(fill)};
      opacity: ${opacity};
      transition: fill 0.25s;
    }
    &:hover {
      & path {
        fill: ${c(hoverfill)};
      }
    }

    ${spacing(props)}
    ${decorations(props)}
    ${positioning(props)}
  `,
)
