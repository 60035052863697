import { css } from 'styled-components'

//The feathering on images removes the outline, it also hides the border given to an element, something to ponder later
export default ({ full, width, height, x, y, z, edits, print }) => css`
  position: absolute;
  width: ${full ? '100%' : width && `${width}px`};
  height: ${full ? '100%' : height && `${height}px`};
  z-index: ${z && `${z}`};
  top: ${print && y && `${y}px`};
  left: ${print && x && `${x}px`};
  ${print && edits?.rotate ? `transform: rotate(${edits.rotate}deg);` : ''}
`
