import styled from 'styled-components'
import {
  flex,
  sizing,
  spacing,
  positioning,
  borders,
  decorations,
  gap,
} from 'styles'

export const Flex = styled.div`
  ${flex}
  ${sizing}
  ${spacing}
  ${positioning}
  ${borders}
  ${decorations}
  ${gap}
`
