import { calculateCaseAge, isoStringToDate, convertObjectOfDates } from 'utils'
import { caseDates as dates } from 'mocks'

export default (originalCase) => {
  const _case = JSON.parse(JSON.stringify(originalCase))

  // calculate the ages, and add it to the case object
  calculateCaseAge(_case)

  // convert all dates in the case object
  convertObjectOfDates(dates.case, _case, isoStringToDate)

  // convert all dates in the case's service array
  _case?.services?.forEach((service) =>
    convertObjectOfDates(dates.service, service, isoStringToDate),
  )

  return _case
}
