import { atom, atomFamily, selectorFamily } from 'recoil'
import { default as _set } from 'lodash/set'
import { default as _get } from 'lodash/get'
import { produce } from 'immer'

export const editorSearchBarResults = atom({
  key: 'editorSearchBarResults',
  default: [],
})

export const editorSearchBarLoading = atom({
  key: 'editorSearchBarLoading',
  default: false,
})

export const canvasRefAtom = atom({
  key: 'canvasRefAtom',
  default: null,
})

export const pageRefAtom = atom({
  key: 'pageRefAtom',
  default: null,
})

export const editorPagesAtom = atom({
  key: 'editorPages',
  default: [],
})

export const editorLayoutAtom = atom({
  key: 'editorLayoutAtom',
  default: null,
})

export const editorOrderPageSetAtom = atom({
  key: 'editorOrderPageSetAtom',
  default: null,
})

export const selectedPageAtom = atom({
  key: 'selectedPage',
  default: 1,
})

export const boundingBoxAtom = atom({
  key: 'boundingBox',
  default: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
})

export const selectionRangeAtom = atom({
  key: 'selectionRange',
  default: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
})

export const pageZoomAtom = atom({
  key: 'pageZoom',
  default: 60,
})

export const pageExceedsCanvasAtom = atom({
  key: 'pageExceedsCanvas',
  default: false,
})

export const snapToggleAtom = atom({
  key: 'SnapToggle',
  default: true,
})

export const pageLoadingAtom = atom({
  key: 'pageLoading',
  default: false,
})

export const itemAtomFamily = atomFamily({
  key: 'itemAtoms',
  default: {},
})

export const itemIdAtom = atom({
  key: 'itemIds',
  default: [],
})

export const watermarkIdAtom = atom({
  key: 'watermarkIds',
  default: [],
})

export const watermarkAtomFamily = atomFamily({
  key: 'watermarkAtoms',
  default: {},
})

export const selectedItemAtom = atom({
  key: 'itemSelectedInEditor',
  default: '',
})

export const selectedItemsAtom = atom({
  key: 'itemsSelectedInEditor',
  default: [],
})

export const controlledXAtom = atom({
  key: 'controlledX',
  default: null,
})
export const controlledYAtom = atom({
  key: 'controlledY',
  default: null,
})
export const alignmentPointsAtom = atom({
  key: 'alignmentPointsObj',
  default: {},
})

export const alignmentVisibilityAtom = atom({
  key: 'alignmentVisibilityObj',
  default: {},
})

export const pageLayoutAtom = atom({
  key: 'pageLayoutAtom',
  default: {},
})

export const showPinturaAtom = atom({
  key: 'showPintura',
  default: false,
})

export const showUnsavedModalAtom = atom({
  key: 'showUnsavedModal',
  default: false,
})

export const fhLogoAtom = atom({
  key: 'fhLogo',
  default: '',
})

export const isPageDeleting = atom({
  key: 'isPageDeleting',
  default: false,
})

export const pageThemeLayoutSelector = selectorFamily({
  key: 'pageThemeLayoutSelector',
  get:
    ({ path }) =>
    ({ get }) => {
      const object = get(pageLayoutAtom)
      if (!object) return null
      if (path) {
        let newValue = _get(object, path)
        if (path.includes('opacity') && newValue !== '')
          newValue = newValue * 100
        if (path.includes('top') || path.includes('left'))
          return parseInt(newValue)
        return newValue
      }
      return object
    },
  set:
    ({ path }) =>
    ({ set, get }, newValue) => {
      const pageLayout = get(pageLayoutAtom)
      if (!pageLayout) return null
      if (path.includes('opacity') && newValue !== '') newValue = newValue / 100

      const newField = produce(pageLayout, (draft) => {
        _set(draft, path, newValue)
      })
      set(pageLayoutAtom, newField)
    },
})

export const unsavedChangesAtom = atom({
  key: 'unsavedChanges',
  default: false,
})

export const itemsSelector = selectorFamily({
  key: 'itemsSelector',
  get:
    () =>
    ({ get }) => {
      const selectedItems = get(selectedItemsAtom)
      if (selectedItems.length === 0) return null
      return selectedItems.map((id) => get(itemAtomFamily(id)))
    },
})

export const itemSelector = selectorFamily({
  key: 'itemSelector',
  get:
    ({ id, path, atomFamily }) =>
    ({ get }) => {
      let atom =
        atomFamily === 'watermark' ? watermarkAtomFamily : itemAtomFamily
      const value = get(atom(id))
      if (!value) return null
      if (path) {
        let newValue = _get(value, path)
        if (path.includes('opacity')) newValue = newValue * 100
        return newValue
      }
      return value
    },
  set:
    ({ id, path, atomFamily }) =>
    ({ set, get }, newValue) => {
      let atom =
        atomFamily === 'watermark' ? watermarkAtomFamily : itemAtomFamily
      const value = get(atom(id))
      if (!value) return null
      if (path.includes('opacity')) newValue = newValue / 100
      const newField = produce(value, (draft) => {
        _set(draft, path, newValue)
      })
      set(atom(id), newField)
      set(unsavedChangesAtom, true)
    },
})

export const rightMenuAtom = atom({
  key: 'activeRightMenu',
  default: '',
})

export const insertMenuOpenAtom = atom({
  key: 'insertMenuOpen',
  default: false,
})

// used to trigger a useEffect if a reliable dependency is not available
export const triggerUseEffect = atom({
  key: 'triggerUseEffect',
  default: Date.now(),
})

export const editorClipboardAtom = atom({
  key: 'editorClipboard',
  default: [],
})

export const editBackgroundImageAtom = atom({
  key: 'editBackgroundImage',
  default: false,
})

export const backgroundImageAtom = atom({
  key: 'backgroundImage',
  default: '',
})

export const themeLayoutAtom = atom({
  key: 'themeLayout',
  default: {},
})

export const designAssetsAtom = atom({
  key: 'designAssets',
  default: [],
})

export const themeIdAtom = atom({
  key: 'themeId',
  default: null,
})

export const draggingSelectionAtom = atom({
  key: 'draggingSelection',
  default: false,
})

export const showRulerAtom = atom({
  key: 'showRuler',
  default: false,
})

export const insetRulerAtom = atom({
  key: 'insetRuler',
  default: true,
})

export const outsideCanvasItemsVisibleAtom = atom({
  key: 'outsideCanvasItemsVisible',
  default: false,
})

export const openSwapImageMenuAtom = atom({
  key: 'openSwapImageMenu',
  default: false,
})
