import styled, { css } from 'styled-components'
import { spacing, sizing, text, decorations, positioning } from 'styles'

export const Text = styled.div(
  ({ ...props }) => css`
    ${text(props)}
    ${spacing(props)}
    ${sizing(props)}
    ${decorations(props)}
    ${positioning(props)}
  `,
)
