import { css } from 'styled-components'

const alignment = ({ center, justify, align }) => css`
  justify-content: ${center ? 'center' : justify};
  align-items: ${center ? 'center' : align};
`

export default ({ wrap, flex, column }) => css`
  display: flex;
  flex-wrap: ${wrap};
  flex: ${flex};
  flex-direction: ${column && 'column'};
  ${alignment}
`
