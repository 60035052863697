import React from 'react'
import { ThemeProvider } from 'styled-components'
import { themeAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { getThemeColors, browserStats } from 'utils'

export default ({ children }) => {
  const theme = useRecoilValue(themeAtom)
  const masterTheme = {
    ...getThemeColors(theme),
    ...browserStats,
    palette: theme,
  }
  return <ThemeProvider theme={masterTheme}>{children}</ThemeProvider>
}
