import { css } from 'styled-components'
import { elements, typeArea } from 'styles'
import { colors as c } from 'utils'

export default css`
  /* DayPicker styles */

  .DayPicker {
    display: inline-block;
    font-size: 1rem;
  }

  .DayPicker-wrapper {
    position: relative;

    flex-direction: row;
    padding-bottom: 1em;
    user-select: none;
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;
    user-select: none;
  }

  .DayPicker-NavBar {
  }

  .DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;

    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: ${c('gray3')};
    cursor: pointer;
  }

  .DayPicker-NavButton svg {
    background: blue;
    fill: blue;
  }

  .DayPicker-NavButton:hover {
    opacity: 0.8;
  }

  .DayPicker-NavButton--prev {
    margin-right: 1.5em;
    left: 1.5rem;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDkgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjAwMDg5MDgxNSA4LjAzNDU3QzAuMDA3NDg0OTIgOC4zMDgyNSAwLjExNjI4NiA4LjU2ODk5IDAuMzA4NjIzIDguNzY3MjNMNy4wNjA4NCAxNS42NjNDNy40OTI3NiAxNi4xMDE1IDguMjA1OTkgMTYuMTEzNCA4LjY1NDM5IDE1LjY5MUM5LjEwMTY3IDE1LjI2NzUgOS4xMTcwNiAxNC41Njk0IDguNjg3MzYgMTQuMTI4N0wyLjY4MjM5IDhMOC42ODczNiAxLjg3MTMzQzkuMTE3MDUgMS40MzA2MyA5LjEwMTY5IDAuNzMyNDUxIDguNjU0MzkgMC4zMDg5OTdDOC4yMDU5OSAtMC4xMTMzNTQgNy40OTI3NiAtMC4xMDE1MjIgNy4wNjA4NCAwLjMzNzAwOUwwLjMwODYyMyA3LjIzMjc3QzAuMDk5ODEzNSA3LjQ0ODI2IC0wLjAxMTE3ODggNy43MzcwMSAwLjAwMDg5MDgxNSA4LjAzNDM5VjguMDM0NTdaIiBmaWxsPSIjQjBCNkJDIi8+Cjwvc3ZnPgo=');
  }

  .DayPicker-NavButton--next {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDkgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04Ljk5OTExIDguMDM0NTdDOC45OTI1MiA4LjMwODI1IDguODgzNzEgOC41Njg5OSA4LjY5MTM4IDguNzY3MjNMMS45MzkxNiAxNS42NjNDMS41MDcyNCAxNi4xMDE1IDAuNzk0MDEyIDE2LjExMzQgMC4zNDU2MDkgMTUuNjkxQy0wLjEwMTY2OSAxNS4yNjc1IC0wLjExNzA1OCAxNC41Njk0IDAuMzEyNjM5IDE0LjEyODdMNi4zMTc2MSA4TDAuMzEyNjM5IDEuODcxMzNDLTAuMTE3MDU1IDEuNDMwNjMgLTAuMTAxNjk0IDAuNzMyNDUxIDAuMzQ1NjA5IDAuMzA4OTk3QzAuNzk0MDEyIC0wLjExMzM1NCAxLjUwNzI0IC0wLjEwMTUyMiAxLjkzOTE2IDAuMzM3MDA5TDguNjkxMzggNy4yMzI3N0M4LjkwMDE5IDcuNDQ4MjYgOS4wMTExOCA3LjczNzAxIDguOTk5MTEgOC4wMzQzOVY4LjAzNDU3WiIgZmlsbD0iI0IwQjZCQyIvPgo8L3N2Zz4K');
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: center;
    color: ${c('gray1')};
  }

  .DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: ${c('gray3')};
    text-align: center;
    font-size: 0.875em;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 0.5em;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: ${c('gray1')};
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid ${c('gray5')};
    color: ${c('gray3')};
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5em;
  }

  .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: ${c('brand')};
    font-size: 0.875em;
    cursor: pointer;
  }

  /* Default modifiers */

  .DayPicker-Day--today {
    color: ${c('brand')};
    font-weight: 700;
  }

  .DayPicker-Day--outside {
    color: ${c('gray3')};
    cursor: default;
  }

  .DayPicker-Day--disabled {
    color: ${c('gray5')};
    cursor: default;
  }

  /* Example modifiers */

  .DayPicker-Day--sunday {
    background-color: ${c('white')};
  }

  .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: ${c('gray5')};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    position: relative;

    background-color: ${c('brand')};
    color: ${c('lockedWhite')};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ):hover {
    background-color: ${c('brand', '80')};
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    background-color: ${c('gray5')};
  }

  /* DayPickerInput */

  .DayPickerInput {
    display: inline-block;
    width: 100%;
  }

  .DayPickerInput input {
    padding: 12px 16px;
    height: 42px;
    width: 100%;
    font-size: 14px;
    ${typeArea}
    ${elements.default}
  }

  .DayPickerInput input::placeholder {
    color: ${c('gray1')};
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
  }
`
