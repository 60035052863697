import { palettes } from 'utils'

export default (colorSet) => {
  const palette = {}
  Object.entries(palettes).forEach(
    ([color, value]) => (palette[color] = value?.[colorSet] || value?.default),
  )

  return palette
}
