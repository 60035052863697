import {
  handleString,
  handleArray,
  handleAdditionalFamily,
  handleOrganizations,
  handleMemorialContributions,
  handleTextBlock,
} from '.'

export default (editor, caseDetails, key, templates, dateFormat) => {
  const obj = {
    // TODO: Add the rest of the keys from a case details object after varifying their type

    // handle strings
    name_of_deceased: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    prefix_dec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'Prefix Name',
      ),
    first_dec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'First Name',
      ),
    nick_name_dec: () =>
      handleString(editor, caseDetails, key, templates, dateFormat, 'Nickname'),
    middle_dec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'Middle Name',
      ),
    maiden_dec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'Maiden Name',
      ),
    lastdec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'Last Name',
      ),
    date_of_birth: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    place_of_birth: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    date_of_death: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    place_of_death: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    religion: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    address_of_deceased: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    mother_of_deceased: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    father_of_deceased: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    spouse: () => handleString(editor, caseDetails, key, templates, dateFormat),
    marital_status: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    occupation: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    website_obit_url: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    obituary: () =>
      handleTextBlock(editor, caseDetails, key, templates, dateFormat),
    order_of_service: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    interment_place: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    interment_address: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    interment_lot: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    interment_section: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    interment_state: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    interment_postal_code: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    interment_block: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    interment_city: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    interment_date: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    interment_county: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),
    interment_time: () =>
      handleString(editor, caseDetails, key, templates, dateFormat),

    // handle arrays
    children: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),
    siblings: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),
    songs: () => handleArray(editor, caseDetails, key, templates, dateFormat),
    special_songs: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),
    pallbearers: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),
    societies: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),
    clergy: () => handleArray(editor, caseDetails, key, templates, dateFormat),
    us_stations_where_served: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),

    // handle services
    // services: () =>
    //   handleServices(editor, caseDetails, key, templates, dateFormat),
    organizations: () =>
      handleOrganizations(editor, caseDetails, key, templates, dateFormat),
    memorial_contributions: () =>
      handleMemorialContributions(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
      ),

    // handle additional family
    additional_family: () =>
      handleAdditionalFamily(editor, caseDetails, key, templates, dateFormat),
  }

  if (obj[key]) {
    obj[key]()
  }
}
