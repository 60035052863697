import { isoStringToDate, convertObjectOfDates } from 'utils'
import { dashboardDates as dates } from 'mocks'

export default (originalCells) => {
  const cells = [...originalCells]

  cells.forEach((cell) => {
    // convert all dates in the dashboard object
    convertObjectOfDates(dates.table, cell, isoStringToDate)

    // convert all dates in the dashboard's service array
    cell?.services?.forEach((service) =>
      convertObjectOfDates(dates.service, service, isoStringToDate),
    )

    // convert all dates in the dashboard's sortable_service_date array
    cell?.sortable_service_date?.forEach((service) =>
      convertObjectOfDates(dates.sortableServiceDate, service, isoStringToDate),
    )
  })

  return cells
}
