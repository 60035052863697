import { isoStringToDate, convertObjectOfDates } from 'utils'
import { searchFHOrderDates as dates } from 'mocks'

export default (originalOrders) => {
  const orders = [...originalOrders]

  orders.forEach((cell) => {
    // convert all dates in the orders object
    convertObjectOfDates(dates.funeralHomeOrders, cell, isoStringToDate)

    // convert all dates in the orders's case object
    cell?.case && convertObjectOfDates(dates.case, cell?.case, isoStringToDate)
  })

  return orders
}
