import handleSpecialCaseDetailArrays from 'utils/tinyMCE/handleSpecialCaseDetailArrays'
import dynamicTextUtilities from './dynamicTextUtilities'
import { tableTemplate } from 'utils/tinyMCE/caseDetails'
import cheerio from 'cheerio'
import preserveStyling from 'utils/tinyMCE/updateDynamicText/preserveStyling'

export default (dynamicTextProps) => {
  const { elementProps } = dynamicTextProps
  const { element } = elementProps

  const { getCaseDetail } = dynamicTextUtilities(dynamicTextProps)
  const { path, value } = getCaseDetail(element)

  const items = handleSpecialCaseDetailArrays({ path, items: value })
  const tableHtml = tableTemplate({
    path,
    items,
  })

  const tableElement = cheerio.load(tableHtml)
  preserveStyling({
    initialElement: element,
    templateElement: tableElement,
  })

  element.replaceWith(tableElement.html('body'))
}

// const caseDetailTableLegend = {
//   pallbearers: {
//     type: 'strings',
//     title: 'Pallbearers',
//   },
//   societies: {
//     type: 'strings',
//     title: 'Societies',
//   },
//   clergy: {
//     type: 'strings',
//     title: 'Clergy',
//   },
//   us_stations_where_served: {
//     type: 'strings',
//     title: 'US stations where served',
//   },
// }
