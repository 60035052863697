import { css } from 'styled-components'

export default ({
  color,
  font,
  size,
  weight,
  fontStyle,
  align,
  transform,
  decoration,
  line,
  spacing,
  zIndex = 0,
  whiteSpace = 'pre-wrap',
}) => css`
  color: ${({ theme }) => theme?.[color] || color || theme?.gray1};
  font-family: ${font};
  font-size: ${size};
  font-weight: ${weight};
  font-style: ${fontStyle};
  text-align: ${align};
  text-transform: ${transform};
  text-decoration: ${decoration};
  line-height: ${line};
  letter-spacing: ${spacing};
  white-space: ${whiteSpace};
  user-select: none;
  z-index: ${zIndex};
`
